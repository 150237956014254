import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../Urls';
import {useSelector} from 'react-redux'

interface Bill {
    billId: any;
    date: string;
    amount: string;
    link: string;
    for: string;
}

const PendingBills: React.FC = () => {
    const [bills, setBills] = useState<Bill[]>([]);
    const [error, setError] = useState<string | null>(null);
    const client = useSelector((state: { auth: any; }) => state.auth)

    useEffect(() => {
        const fetchPendingBills = async () => {
            try {
                const url = `${ROOT_URL}/client/get/pending-bills`;
                const response = await axios.post(url, { id: client.userId });

                if (response.status === 200) {
                    // Assuming response.data.data is an array of bills
                    setBills(response.data.data);
                } else if(response.status === 202){ 
                    setBills([])
                    setError('No pending bills found');
                }
            } catch (err) {
                setError('An error occurred while fetching pending bills');
            }
        };

        fetchPendingBills();
    }, [bills]);

        const handlePayNow = async (bill: Bill) => {
            try {
                const url = `${ROOT_URL}/payment/create-order`;
                const response = await axios.post(url, { amount: bill.amount,billId:bill.billId });

                if (response.status === 200) {
                    const { order_id, amount, currency, key } = response.data;
                    const options = {
                        key,
                        amount: amount.toString(),
                        currency,
                        name: 'Chyatech software solutions',
                        description: `Payment for ${bill.for}`,
                        order_id,
                        handler: (response: any) => {
                            // Handle successful payment
                            console.log('Payment successful:', response);
                            // You can call an API to update the payment status
                            const url = `${ROOT_URL}/payment/update-payment-status`;
                            axios.post(url, {razorpay_order_id: order_id,razorpay_payment_id : response.razorpay_payment_id });

                        
                        },
                        prefill: {
                            email: 'example@example.com',
                            contact: '1234567890',
                        },
                    };
                    const razorpay = new (window as any).Razorpay(options);
                    razorpay.open();
                    setBills(bills);
                } else {
                    setError('Failed to create payment order');
                }
            } catch (err:any) {
                setError(err.message);
            }
        };
    

    return (
        <div className='bg-gray-900 p-6 rounded-lg shadow w-full'>
            <h2 className='text-xl font-semibold text-white mb-4'>Pending Bills</h2>
            {error && <div className='bg-red-600 text-white p-6 rounded-lg'>{error}</div>}
            {bills.length > 0 ?(
            <table className='w-full table-auto'>
                <thead>
                    <tr>
                        <th className='text-left px-4 py-2 text-gray-400'>Payment For</th>
                        <th className='text-left px-4 py-2 text-gray-400'>Due Date</th>
                        <th className='text-left px-4 py-2 text-gray-400'>Amount</th>
                        <th className='text-left px-4 py-2 text-gray-400'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {bills.map((bill, index) => (
                        <tr key={index} className='hover:bg-gray-700'>
                            <td className='px-4 py-2 border-t border-gray-700 text-white'>{bill.for}</td>
                            <td className='px-4 py-2 border-t border-gray-700 text-white'>{bill.date}</td>
                            <td className='px-4 py-2 border-t border-gray-700 text-white'>{bill.amount}</td>
                            <td className='px-4 py-2 border-t border-gray-700 text-white'>
                                <button
                                    onClick={() => handlePayNow(bill)}
                                    className='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-3 rounded'
                                >
                                    Pay Now
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>) : ''}
        </div>
    );
};

export default PendingBills;
