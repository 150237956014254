import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useSelector,useDispatch } from 'react-redux';
import {ROOT_URL } from '../Urls';
import { success, error, warning } from '../Config/notify';
import { isValidMailAddress } from '../Config/validation';
import { setUser,setAuthToken,setUserId,setClientName } from '../Redux/Reducer/authSlice';




export default function PatnerAuth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth)

  useEffect(() => {
    document.title = 'Chyatech | Patner Login';
  }, []);
  useEffect(() => {
    console.log('User state updated:', user); // Monitor state updates
  }, [user]);
  const handleSignin = async (e) => {
    e.preventDefault();
    if (!isValidMailAddress(email)) {
      warning('Please re-check your email address!');
      return;
    }
    const url = `${ROOT_URL}/client/login`;
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        success('Welcome Back');
        //handle state
        const token = response.data.data;
      console.log(token)
        // Decode the JWT
        const decodedToken = jwtDecode(token);
    
        // Extract the user ID from the decoded token
        const userId = decodedToken.id;
        const clientName = decodedToken.name
    
        // Dispatch actions to store the token and user ID
        dispatch(setUser(true))
        dispatch(setAuthToken(token));
        dispatch(setUserId(userId));
        dispatch(setClientName(clientName));
        console.log(user)
        navigate('/patner/client/dashboard');
      } else {
        error('Something went wrong');
      }
    } catch (err) {
      error(err.message);
    }
  };

  return (
    <div style={{ width: '100vw', height: '100vh' }} className="flex justify-center items-center bg-black">
      <div className="patnerLogin rounded-lg shadow-2xl flex justify-center items-center p-8 bg-dark-yellow max-w-md w-full">
        <div className="w-full">
          <p className="text-4xl text-black font-bold">Hello Again :)</p>
          <p className="text-md text-gray-800 mb-6">Welcome Back! Please sign in to continue.</p>
          <form className="w-full" onSubmit={handleSignin}>
            <div className="mb-4">
              <label className="block text-black text-sm font-bold mb-2" htmlFor="username">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:ring-2 focus:ring-black focus:shadow-outline"
                id="username"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label className="block text-black text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <input
                className="shadow appearance-none border rounded-lg w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:ring-2 focus:ring-black focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-black text-dark-yellow font-bold py-2 px-4 rounded-lg hover:bg-dark-yellow hover:text-black transition duration-300 focus:outline-none focus:ring-2 focus:ring-black focus:shadow-outline"
                type="submit"
              >
                Sign In
              </button>
            </div>
          </form>
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-700">
              Forgot your login credentials? Please contact our support team at{' '}
              <a href="mailto:clientsupport@chyatech.online" className="text-black font-semibold underline">
                clientsupport@chyatech.online
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
