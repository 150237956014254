import React from 'react';

const AboutUs = () => {
  return (
    <div className="relative overflow-hidden bg-gradient-to-r from-black to-gray-800 text-white py-12">
      <div className="absolute inset-0 -z-10">
        <svg className="absolute top-0 right-0 w-full h-full" viewBox="0 0 1200 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="url(#gradient1)" d="M0 0H1200V600H0V0Z" />
          <path fill="url(#gradient2)" d="M0 600H1200V800H0V600Z" />
          <defs>
            <linearGradient id="gradient1" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor="#1a1a1a" />
              <stop offset="100%" stopColor="#333333" />
            </linearGradient>
            <linearGradient id="gradient2" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor="#2b2b2b" />
              <stop offset="100%" stopColor="#404040" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative z-10 max-w-6xl mx-auto px-4">
        <h2 className="text-4xl font-extrabold text-yellow-500 mb-8 text-center">About Chyatech</h2>
        <div className="bg-gray-900 text-gray-200 rounded-lg shadow-lg p-8 mb-8">
          <p className="text-lg mb-6 text-center">
            At Chyatech, we are committed to transforming your vision into reality through innovative and cutting-edge technology solutions. Our mission is to empower businesses by delivering high-quality services and products that drive growth and success.
          </p>
          <div className="mb-6">
            <h3 className="text-2xl font-bold mb-4 text-yellow-500">Our Core Values</h3>
            <ul className="list-disc list-inside">
              <li className="mb-2">
                <strong>Integrity:</strong> We uphold the highest standards of integrity and transparency in all our dealings.
              </li>
              <li className="mb-2">
                <strong>Excellence:</strong> We strive for excellence in every project, ensuring top-notch quality and performance.
              </li>
              <li className="mb-2">
                <strong>Innovation:</strong> We embrace innovation to stay ahead in technology and deliver state-of-the-art solutions.
              </li>
              <li className="mb-2">
                <strong>Client-Centric:</strong> Our clients are at the heart of everything we do. We tailor our services to meet their unique needs and goals.
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4 text-yellow-500">Why Choose Us?</h3>
            <p className="text-lg">
              Our team of experts brings a wealth of experience and a diverse skill set to each project. From web design and development to SEO and software testing, we offer a comprehensive range of services that can scale with your business. We are dedicated to providing solutions that not only meet but exceed your expectations, ensuring your success in the digital landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
