export function isValidMailAddress(email) {
    // Regular expression pattern for email validation
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the pattern
    if (pattern.test(email)) {
        return true;
    } else {
        return false;
    }
}

