import axios from 'axios';
import React, { useEffect } from 'react';
import { useTable, Column } from 'react-table';
import { ROOT_URL } from '../Urls';
import { useSelector } from 'react-redux';
interface Payment {
    date: string;
    amount: string;
    status: 'Paid' | 'Pending';
    method: string;
}

const PaymentHistory: React.FC = () => {
    const [paymentData, setPaymentData] = React.useState<Payment[]>([]);
    const [error, setError] = React.useState<string | null>(null);
    const client = useSelector((state: { auth: any; }) => state.auth)


    useEffect(() => {
        const fetchPaymentData = async () => {
            try {
                const url = `${ROOT_URL}/client/get/payment-history`;
                const response = await axios.post(url, { id:client.userId });

                if (response.status === 200) {
                    // Check if the response contains a payment object and convert it into an array
                    const data = response.data.data;
                    if (data) {
                        // Ensure response.data.data is an array
                        setPaymentData(data);
                    } else {
                        setPaymentData([]); // Set to empty array if no data is found
                    }
                }
                else if(response.status === 202){
                    setPaymentData([]);
                    setError("No paid bills found")
                }
                else {
                    setError('Failed to fetch payment data');
                }
            } catch (err) {
                setError('An error occurred while fetching payment data');
            }
        };
        fetchPaymentData();
    }, []);

    const columns: Column<Payment>[] = React.useMemo(
        () => [
            {
                Header: 'Paid Date',
                accessor: 'date'
            },
            {
                Header: 'Amount',
                accessor: 'amount'
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }: { value: 'Paid' | 'Pending' }) => (
                    <span
                        className={`px-2 py-1 rounded text-white ${
                            value === 'Paid' ? 'bg-green-500' : 'bg-red-500'
                        }`}
                    >
                        {value}
                    </span>
                )
            },
            {
                Header: 'Method',
                accessor: 'method'
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({ columns, data: paymentData });

    return (
        <div className='bg-gray-900 p-6 rounded-lg shadow w-full'>
            <h2 className='text-xl font-semibold text-white mb-4'>Payment History</h2>
            {error && <div className='bg-red-600 text-white p-6 rounded-lg'>{error}</div>}
            {paymentData.length > 0 ? (
            <table {...getTableProps()} className='w-full table-auto'>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    className='text-left px-4 py-2 text-gray-400'
                                    key={column.id}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={row.id} className='hover:bg-gray-700'>
                                {row.cells.map(cell => (
                                    <td
                                        {...cell.getCellProps()}
                                        key={cell.column.id}
                                        className='px-4 py-2 border-t border-gray-700 text-white'
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>) : ''}
        </div>
    );
};

export default PaymentHistory;
