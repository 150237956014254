import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  user: false,
  userId: null,
  authToken: null,
  clientName:null
};

// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
   setClientName:(state,action) =>{
    state.clientName = action.payload;
   }
    ,
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    logout: (state) => {
      state.user = false;
      state.authToken = null;
      state.userId = null;
    },
  },
});

// Export the actions and reducer
export const { logout, setUser, setAuthToken, setUserId,setClientName } = authSlice.actions;

export default authSlice.reducer;
