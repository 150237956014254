import React from 'react';

const ThankYouMessage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="text-white p-6 rounded shadow-md text-center">
        <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
        <p className="text-gray-700">Your submission has been received.</p>
      </div>
    </div>
  );
};

export default ThankYouMessage;
