import React, { useState } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../Urls'; // Adjust the import path as needed
import { useSelector } from 'react-redux';
const AccountSettings: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const client = useSelector((state: { auth: any; }) => state.auth)


    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            alert("New password and confirm password do not match!");
            return;
        }

        try {
            const url = `${ROOT_URL}/client/update-password`;
            const response = await axios.post(url, {
                currentPassword,
                newPassword,
                id: client.userId // Replace with the actual client ID
            });

            if (response.status === 200) {
                setSuccessMessage('Password updated successfully');
                setError(null);
            } else {
                setError('Failed to update password');
                setSuccessMessage(null);
            }
        } catch (err) {
            setError('An error occurred while updating password');
            setSuccessMessage(null);
        }
    };

    return (
        <div className='bg-gray-900 p-6 rounded-lg shadow w-full max-w-md mx-auto'>
            <h2 className='text-xl font-semibold text-white mb-4'>Account Settings</h2>
            <div className='space-y-4'>
                <div>
                    <label className='block text-gray-400 mb-1'>Current Password</label>
                    <input
                        type='password'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className='w-full p-2 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
                    />
                </div>
                <div>
                    <label className='block text-gray-400 mb-1'>New Password</label>
                    <input
                        type='password'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className='w-full p-2 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
                    />
                </div>
                <div>
                    <label className='block text-gray-400 mb-1'>Confirm New Password</label>
                    <input
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='w-full p-2 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
                    />
                </div>
                <div>
                    <button
                        onClick={handlePasswordChange}
                        className='w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded'
                    >
                        Change Password
                    </button>
                </div>
                {error && <div className='bg-red-600 text-white p-4 rounded'>{error}</div>}
                {successMessage && <div className='bg-green-600 text-white p-4 rounded'>{successMessage}</div>}
            </div>
        </div>
    );
};

export default AccountSettings;
