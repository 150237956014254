import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function OfficialPatners() {
  const patners = [
    {name: 'https://img.icons8.com/color/48/google-logo.png', title: 'Google', color: 'violet-800', description: 'Leverage the power of Google’s robust ecosystem to drive growth and innovation.'},
    {name: 'https://img.icons8.com/color/48/paypal.png', title: 'PayPal', color: 'blue-800', description: 'Secure and efficient payment solutions that enhance your customer’s shopping experience.'},
    {name: 'https://img.icons8.com/color/48/phone-pe.png', title: 'PhonePe', color: 'green-800', description: 'Simplify transactions and increase conversion rates with seamless payment integration.'},
    {name: 'https://img.icons8.com/color/48/paytm.png', title: 'Paytm', color: 'cyan-800', description: 'Expand your business reach with India’s leading digital wallet and payment platform.'}
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 5000,
    cssEase: "linear"
  };

  return (
    <div className='relative text-white bg-black mb-3 py-10'>
      <div className='absolute inset-0 bg-fixed bg-cover bg-center opacity-40' style={{ backgroundImage: `url('https://example.com/your-background-image.jpg')` }}></div>
      <div className='relative z-10 max-w-6xl mx-auto'>
        <p className='text-3xl font-bold text-center mb-6 text-dark-yellow'>Our Integration Partners</p>
        <p className='text-lg text-center font-semibold mb-10'>
          Partnering with industry leaders to ensure the success and scalability of your business. Our carefully selected integration partners provide cutting-edge technology solutions that enhance your business operations and customer experience.
        </p>
        <Slider {...settings} className='integrationPatner flex items-center justify-around'>
          {patners.map((item, index) => (
            <div key={index} className={`px-4 py-4 flex flex-col justify-center items-center bg-white text-black rounded-lg shadow-lg`}>
              <img width="48" height="48" src={item.name} alt={item.title} className='mb-4' />
              <p className={`text-xl font-bold text-center text-${item.color}`}>{item.title}</p>
              <p className='text-center text-md font-semibold mt-2'>{item.description}</p>
            </div>
          ))}
        </Slider>
        <p className='text-lg text-center font-semibold mt-10'>
          By integrating with these trusted partners, your business can scale rapidly, ensuring smooth operations and enhanced customer satisfaction. Trust us to help you grow and succeed in the digital age.
        </p>
      </div>
    </div>
  );
}
