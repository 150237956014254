import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function TechStack() {
  let tech = [
    { name: 'HTML5', src: 'https://img.icons8.com/color/48/html-5--v1.png', description: 'Builds the structure of web pages, ensuring robust and SEO-friendly websites.' },
    { name: 'CSS3', src: 'https://img.icons8.com/color/48/css3.png', description: 'Designs visually appealing and responsive websites, enhancing user experience.' },
    { name: 'JavaScript', src: 'https://img.icons8.com/color/48/javascript--v1.png', description: 'Enables interactive and dynamic content, improving user engagement.' },
    { name: 'React', src: 'https://img.icons8.com/office/40/react.png', description: 'Creates fast, scalable, and modern user interfaces, making your application more efficient.' },
    { name: 'MySQL', src: 'https://img.icons8.com/color/48/my-sql.png', description: 'Manages large datasets with high performance, ensuring reliable data storage.' },
    { name: 'MongoDB', src: 'https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/external-mongodb-a-cross-platform-document-oriented-database-program-logo-shadow-tal-revivo.png', description: 'Handles unstructured data efficiently, perfect for scalable and flexible applications.' },
    { name: 'Node.js', src: 'https://img.icons8.com/color/48/nodejs.png', description: 'Powers the backend with high-speed processing, ideal for real-time applications.' },
    { name: 'Express.js', src: 'https://img.icons8.com/color/48/express-js.png', description: 'Simplifies backend development, speeding up project delivery.' },
    { name: 'Java', src: 'https://img.icons8.com/fluency/48/java-coffee-cup-logo.png', description: 'Provides a secure and scalable environment for enterprise-level applications.' },
    { name: 'Python', src: 'https://img.icons8.com/color/48/python--v1.png', description: 'Supports AI, machine learning, and data analysis, driving innovation in your business.' }
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 8000,
    cssEase: "linear"
  };

  return (
    <div className='bg-gradient-to-r from-gray-900 via-gray-800 to-black text-white py-12'>
      <p className='text-3xl font-semibold text-center mb-8 text-dark-yellow'>Our Tech Stack</p>
      <Slider {...settings} className='integrationPatner flex items-center justify-around'>
        {tech.map((item, index) => (
          <div key={index} className='px-4 py-4 flex flex-col items-center'>
            <img width="48" height="48" src={item.src} alt={item.name} />
            <p className='text-lg font-semibold mt-4'>{item.name}</p>
            <p className='text-sm text-gray-400 text-center mt-2'>{item.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}
