import React, { useState } from 'react';
import axios from 'axios';
import ThankYouMessage from './ThankYouMessage';
import { error, success } from '../Config/notify';
import { ROOT_URL } from '../Urls';

const ClientForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhoneNumber: '',
    clientRequirement: '',
    clientNote: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const url = `${ROOT_URL}/client/new/form`;
    try {
      const response = await axios.post(url, formData);
      if (response.status === 201) {
        success("Your form has been submitted successfully!");
        localStorage.setItem('isFormSubmitted', 'true');
        setIsSubmitted(true);
      } else {
        error("Something went wrong, please try again later.");
      }
    } catch (err: any) {
      error(err.message);
    }
  };

  if (isSubmitted) {
    return <ThankYouMessage />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-black text-white">
      <form onSubmit={handleSubmit} className="p-8 rounded-lg shadow-lg bg-gray-800 w-full max-w-lg">
        <h2 className="text-3xl font-extrabold text-yellow-500 mb-6">Client Form</h2>
        <div className="mb-4">
          <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="clientName">
            Client Name
          </label>
          <input
            type="text"
            name="clientName"
            id="clientName"
            value={formData.clientName}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 bg-gray-100 focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="clientPhoneNumber">
            Phone Number
          </label>
          <input
            type="text"
            name="clientPhoneNumber"
            id="clientPhoneNumber"
            value={formData.clientPhoneNumber}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 bg-gray-100 focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="clientEmail">
            Email Address
          </label>
          <input
            type="email"
            name="clientEmail"
            id="clientEmail"
            value={formData.clientEmail}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 bg-gray-100 focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="clientRequirement">
            Client Requirement
          </label>
          <select
            name="clientRequirement"
            id="clientRequirement"
            value={formData.clientRequirement}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 bg-gray-100 focus:outline-none focus:shadow-outline"
            required
          >
            <option value="" disabled>Select an option</option>
            <option value="e-commerce site">E-commerce Site</option>
            <option value="admin dashboard">Admin Dashboard</option>
            <option value="chatbot">Chatbot</option>
            <option value="personalized website">Personalized Website</option>
            <option value="others">Others</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 text-sm font-bold mb-2" htmlFor="clientNote">
            Client Note
          </label>
          <textarea
            name="clientNote"
            id="clientNote"
            value={formData.clientNote}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 bg-gray-100 focus:outline-none focus:shadow-outline"
            rows={4}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
