import React from 'react';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  const handleForm = () => {
    let isSubmit = localStorage.getItem('isFormSubmitted');
    if (isSubmit) {
      navigate('/form-already-submit');
    } else {
      navigate('/client/form');
    }
  };

  return (
    <div className="bg-gradient-to-r from-blue-800 via-purple-700 to-black text-white py-24 text-center relative">
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-5xl font-extrabold mb-6">
          Transform Your Business with <span className="text-yellow-400">Chyatech</span>
        </h1>
        <p className="text-lg mb-10">
          Are you struggling to scale your eCommerce platform? Looking for AI-driven solutions to enhance customer engagement? At Chyatech, we turn your challenges into opportunities with our cutting-edge software solutions.
        </p>
        <div className="flex justify-center">
          <button
            onClick={handleForm}
            className="bg-yellow-400 text-black font-semibold py-3 px-8 rounded-full shadow-lg hover:bg-yellow-500 transition transform hover:-translate-y-1 duration-300"
          >
            Let's Solve Your Problems
          </button>
        </div>
        <div className="absolute inset-0 pointer-events-none">
          <div className="h-full w-full bg-gradient-to-t from-transparent via-black opacity-50"></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
