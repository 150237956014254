import React from 'react'
import Navbar from './Navbar';
import Message from './Message'
import Footer from './Footer';
import ContactUs from './ContactUs';
import Hero from './Hero';
import TechStack from './TechStack';
import OfficialPatners from './OfficialPatners';
import HowWork from './HowWork';
import OurPromise from './OurPromise';
import OurServices from './OurServices';
import AboutUs from './AboutUs';
export default function Home() {
  return (
    <>
    <Navbar/>
    <div  className='mb-3 '>
    <Hero/>
    </div>
    <div id='chyatech-service' className='mb-3 mt-3'>
    <OurServices/>
    </div>
    <div className='mb-3 mt-3 hidden'>
    <TechStack/>
    </div>
    <div id='chyatech-about' className='mb-3 mt-3'>
    <AboutUs/>
    </div>
    <div className='mb-3 mt-3'>
    <HowWork/>
    </div>
    <div className='mb-4 mt-4  '>
    <OurPromise/>
    </div>
    <div id='chyatech-contact' className='mb-3 mt-3'>
    <ContactUs/>
    </div>
    <div className='mb-3 mt-3'>
    <OfficialPatners/>
    </div>
    <Footer/>
   
    
    </>
  )
}
