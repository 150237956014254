import React from 'react';
import './App.css';
import Home from './Component/Home';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import PatnerAuth from './Patners/PatnerAuth';
import ClientDashboard from './Component/ClientDashboard'
import ClientForm from './Component/ClientForm';
import ThankYouMessage from './Component/ThankYouMessage';
import CurrentProject from './Component/CurrentProject';
import PendingBills from './Component/PendingBills';
import AccountSettings from './Component/AccountSettings';
import PaymentHistory from './Component/PaymentHistory';

function App() {
  const router = createBrowserRouter([
    {
      path:'/',
      element:<Home/>
    },
    {path:'/client/form',
      element:<ClientForm/>
    }
    ,
    {path:'/form-already-submit',element:<ThankYouMessage/>}
    ,
    {
      path:'/patner/auth',
      element:<PatnerAuth/>
    },
    {
      path:'/patner/client/dashboard',
      element:<ClientDashboard/>,
      children:[{
        path:'/patner/client/dashboard/project',
        element:<CurrentProject/>
      },{
        path:'/patner/client/dashboard/pending-bills',
        element:<PendingBills/>
      },
    {path:'/patner/client/dashboard/account-settings',
      element:<AccountSettings/>
    },
  {path:'/patner/client/dashboard/payment-histroy',
    element:<PaymentHistory/>
  }]

    }
  ])
  return (
    <>
  <RouterProvider router={router} />
  </>
  );
}

export default App;
