import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IoMdTime } from "react-icons/io";
import { BiSupport } from "react-icons/bi";
import { RiUserSmileLine } from "react-icons/ri";
import { MdPriorityHigh } from "react-icons/md";

export default function OurPromise() {
    let promise = [
        {
          name: 'On-Time Delivery',
          logo: <IoMdTime />,
          content: 'We ensure your projects are delivered on time, every time. Our precise planning and dedicated execution keep your business on track.'
        },
        {
          name: 'Client Satisfaction',
          logo: <RiUserSmileLine />,
          content: 'Your success is our success. We listen, adapt, and innovate to meet your needs, ensuring a partnership that’s built to last.'
        },
        {
          name: '24/7 Support',
          logo: <BiSupport />,
          content: 'No matter the time, we’re here for you. Our global support team is ready to assist you, ensuring smooth operations around the clock.'
        },
        {
          name: 'Priority Service',
          logo: <MdPriorityHigh />,
          content: 'You are our priority. We allocate the best resources and attention to your project, ensuring it gets the focus it deserves.'
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 8000,
        cssEase: "linear"
    };

    return (
        <div className='relative text-white py-16'>
            <div className='absolute inset-0 bg-fixed bg-cover bg-center opacity-50' style={{ backgroundImage: `url('https://example.com/your-hero-image.jpg')` }}></div>
            <div className='relative z-10 max-w-6xl mx-auto px-4'>
                <p className='text-4xl font-extrabold text-center mb-12 text-dark-yellow'>
                    Our Promise: Your Success, Delivered
                </p>
                <Slider {...settings} className='flex items-center justify-around'>
                    {promise.map((item, index) => (
                        <div key={index} className='px-8 py-8 flex flex-col justify-center items-center text-center bg-gradient-to-r from-blue-900 to-purple-900 rounded-lg shadow-2xl transform transition-transform duration-500 hover:scale-105'>
                            <div className='text-7xl mb-4'>
                                {item.logo}
                            </div>
                            <p className='text-3xl font-bold mb-3'>{item.name}</p>
                            <p className='text-lg'>{item.content}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}
