import React, { useState, useEffect } from 'react';
import { ROOT_URL } from '../Urls';
import axios from 'axios';
import Loader from './Loader';
import { useSelector } from 'react-redux';

// Define the type for the project data
interface Project {
  name: string;
  budget: number;
  paymentStatus: 'Completed' | 'Pending';
  workingDays: number;
  currentStatus: 'In Progress' | 'Completed' | 'On Hold';
}

const CurrentProject: React.FC = () => {
  const [project, setProject] = useState<Project | null>(null);
  const [error, setError] = useState<string | null>(null);
  const client = useSelector((state: { auth: any; }) => state.auth)

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const url = `${ROOT_URL}/client/get/client`;
        const response = await axios.post(url, { id: client.userId });
        if (response.status === 200) {
          setProject(response.data.data);
        } else {
          setError('Failed to fetch project data');
        }
      } catch (err) {
        setError('An error occurred while fetching project data');
      }
    };
    fetchProject();
  }, [error]);

  if (error) {
    return <div className="bg-red-600 text-white p-6 rounded-lg">Error: {error}</div>;
  }

 

  return (
    <div className='bg-gray-900 p-6 rounded-lg shadow w-full'>
      <h2 className='text-xl font-semibold text-white mb-4'>Current Project</h2>
      <div className='space-y-4'>
        <div className='flex justify-between items-center'>
          <p className='text-gray-400'>Project Name:</p>
          <p className='text-white font-medium'>{project?.name}</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-gray-400'>Budget:</p>
          <p className='text-white font-medium'>₹{project?.budget}</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-gray-400'>Payment Status:</p>
          <p className={`px-2 py-1 rounded text-white ${project?.paymentStatus === 'Completed' ? 'bg-green-500' : 'bg-red-500'}`}>
            {project?.paymentStatus}
          </p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-gray-400'>Working Days:</p>
          <p className='text-white font-medium'>{project?.workingDays}</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-gray-400'>Current Status:</p>
          <p className={`px-2 py-1 rounded text-white ${project?.currentStatus === 'In Progress' ? 'bg-yellow-500' : project?.currentStatus === 'Completed' ? 'bg-green-500' : 'bg-gray-500'}`}>
            {project?.currentStatus}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrentProject;
