import React from 'react';

const services = [
  {
    name: 'SEO Optimization',
    description: `Enhance your online visibility and drive more organic traffic to your site. Our SEO experts employ the latest techniques and best practices to ensure your website ranks higher in search engine results, helping you reach a larger audience and achieve your business goals.`,
    icon: '📈', // SEO Icon
  },
  {
    name: 'Web Design',
    description: `Create a stunning and user-friendly design that captures your brand’s essence. Our web design services focus on creating visually appealing and highly functional designs that engage users and provide a seamless experience across all devices.`,
    icon: '🎨', // Design Icon
  },
  {
    name: 'Website Development',
    description: `Build a robust and scalable website tailored to your needs. From custom coding to integrating advanced features, our development team ensures that your website is fast, secure, and performs optimally, providing a solid foundation for your online presence.`,
    icon: '💻', // Development Icon
  },
  {
    name: 'Converting Static Website to Dynamic',
    description: `Transform your static website into a dynamic, interactive experience. We utilize modern technologies to add functionality and interactivity, allowing for real-time updates and better user engagement.`,
    icon: '🔄', // Conversion Icon
  },
  {
    name: 'Software Testing Support',
    description: `Ensure the quality and reliability of your software with our comprehensive testing services. We provide thorough testing solutions to identify and fix issues, ensuring your software meets the highest standards of performance and usability.`,
    icon: '🧪', // Testing Icon
  },
];

const OurServices = () => {
  return (
    <div className="w-full bg-black py-12">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-yellow-500 mb-8">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-gray-900 shadow-lg rounded-lg p-6 flex flex-col items-center text-center">
              <div className="text-4xl mb-4 text-yellow-500">{service.icon}</div>
              <h3 className="text-xl font-semibold mb-2 text-yellow-500">{service.name}</h3>
              <p className="text-gray-300">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
