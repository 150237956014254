import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Reducer/authSlice';
import { persistStore,persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig,authReducer);

// Configure the Redux store
 const store = configureStore({
  reducer: {
    auth: persistedReducer, // Add the auth slice to the store
  },
});

export const persistor = persistStore(store);
export default store;