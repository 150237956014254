import React from 'react';
import { MdFlight } from "react-icons/md";

export default function HowWork() {
  return (
    <div className='bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white py-12'>
      <p className='text-3xl font-bold text-center mb-8 text-dark-yellow'>How We Work</p>
      <div className='time-line space-y-8'>
        <div className='timeline-box bg-white/10 p-6 shadow-xl shadow-blue-500/50 rounded-lg'>
          <div className='flex items-center mb-4'>
            <p className='text-5xl font-bold mr-4'>1</p>
            <p className='text-xl font-semibold'>Requirement Analysis</p>
          </div>
          <p className='text-gray-300'>We start by thoroughly understanding your needs. Our team conducts detailed consultations to ensure every aspect of your project is clear. This helps us set a strong foundation for a successful outcome.</p>
        </div>

        <div className='timeline-box bg-white/10 p-6 shadow-xl shadow-yellow-500/50 rounded-lg'>
          <div className='flex items-center mb-4'>
            <p className='text-5xl font-bold mr-4'>2</p>
            <p className='text-xl font-semibold'>Design</p>
          </div>
          <p className='text-gray-300'>Our designers craft a detailed plan, focusing on both the architecture and the user interface. We create intuitive, visually appealing designs that align with your brand, ensuring a seamless user experience.</p>
        </div>

        <div className='timeline-box bg-white/10 p-6 shadow-xl shadow-cyan-500/50 rounded-lg'>
          <div className='flex items-center mb-4'>
            <p className='text-5xl font-bold mr-4'>3</p>
            <p className='text-xl font-semibold'>Development</p>
          </div>
          <p className='text-gray-300'>Our development team brings the design to life using cutting-edge technologies. We follow best practices and agile methodologies to ensure a smooth and efficient development process.</p>
        </div>

        <div className='timeline-box bg-white/10 p-6 shadow-xl shadow-orange-500/50 rounded-lg'>
          <div className='flex items-center mb-4'>
            <p className='text-5xl font-bold mr-4'>4</p>
            <p className='text-xl font-semibold'>Testing</p>
          </div>
          <p className='text-gray-300'>Quality is paramount. We conduct rigorous testing at every stage to ensure your software is bug-free and performs optimally. This includes unit testing, integration testing, and user acceptance testing.</p>
        </div>

        <div className='timeline-box bg-white/10 p-6 shadow-xl shadow-indigo-500/50 rounded-lg'>
          <div className='flex items-center mb-4'>
            <p className='text-5xl font-bold mr-4'>5</p>
            <p className='text-xl font-semibold'>Deployment</p>
          </div>
          <p className='text-gray-300'>Once everything is perfect, we deploy the software to the production environment. We ensure a smooth transition and make your application live, ready to deliver value to your users.</p>
        </div>
      </div>
    </div>
  );
}
