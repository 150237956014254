import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaLock } from "react-icons/fa";

const Navbar: FC = () => {
  const navigate = useNavigate();

  const handlePatnerBtnClick = () => {
    navigate("/patner/auth");
  };

  return (
    <nav className="bg-black text-dark-yellow shadow-lg px-6 py-4 flex flex-col md:flex-row items-center justify-between relative">
      <div className="flex justify-between items-center w-full">
        <p
          className="text-2xl font-semibold cursor-pointer"
          onClick={() => navigate("/")}
        >
          Chyatech Softwares
        </p>

        <div className="md:hidden">
          <button
            onClick={handlePatnerBtnClick}
            className="bg-dark-yellow text-black text-sm font-semibold px-3 py-1 rounded-lg hover:bg-yellow-600 transition duration-300"
          >
            <div className="flex justify-center items-center">
              <FaLock size={12} />
              <p className="px-1 font-medium text-sm">Client</p>
            </div>
          </button>
        </div>
      </div>

      <div className="hidden md:flex md:items-center md:space-x-6 flex-col md:flex-row w-full md:w-auto bg-black md:bg-transparent transition-all duration-300">
        <button
         
          className="block text-lg font-medium px-4 py-2 text-dark-yellow hover:bg-gray-800 transition-colors duration-300"
        >
        <a href="#chyatech-service">  Services </a>
        </button>
        <button
         
          className="block text-lg font-medium px-4 py-2 text-dark-yellow hover:bg-gray-800 transition-colors duration-300"
        >
        <a href="#chyatech-about">  About </a>
        </button>
        <button
          
          className="block text-lg font-medium px-4 py-2 text-dark-yellow hover:bg-gray-800 transition-colors duration-300"
        >
        <a href="#chyatech-contact">  Contact </a>
        </button>

        <div className="mt-4 md:mt-0 px-2">
          <button
            onClick={handlePatnerBtnClick}
            className="bg-dark-yellow text-black text-lg font-semibold px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-300"
          >
            <div className="flex justify-center items-center">
              <FaLock />
              <p className="px-2 font-medium">Client</p>
            </div>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
