import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const openModal = (title:string, content:any) => {
    setModalTitle(title);
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black text-white rounded-t-lg">
      <div className="max-w-7xl mx-auto py-10 px-4 md:px-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="mb-8 md:mb-0">
            <p className="text-4xl font-extrabold tracking-tight">Chyatech Software Solutions</p>
            <p className="mt-2 text-lg font-medium">
              Famous Chowk, New Sanghavi, Pimpri-Chinchwad, Pune, Maharashtra, 411027, India
            </p>
          </div>
          <div className="grid grid-cols-2 gap-8 md:gap-20">
            <div>
              <p
                className="text-lg font-semibold cursor-pointer hover:text-gray-300"
                onClick={() => openModal('Terms and Conditions', `Welcome to Chyatech Software Solutions! These terms and conditions outline the rules and regulations for the use of Chyatech Software's Website, located at https://chyatech.online. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Chyatech Software if you do not agree to all of the terms and conditions stated on this page.`)}
              >
                Terms of Use
              </p>
              <p
                className="text-lg font-semibold cursor-pointer hover:text-gray-300 mt-4"
                onClick={() => openModal('Privacy Policy', `Your privacy is important to us. It is Chyatech Software's policy to respect your privacy regarding any information we may collect from you across our website, https://chyatech.online, and other sites we own and operate.`)}
              >
                Privacy Policy
              </p>
            </div>
            <div>
              <p
                className="text-lg font-semibold cursor-pointer hover:text-gray-300"
                onClick={() => openModal('Refunds and Cancellations Policy', `If you are not satisfied with your purchase, you can request a refund within 7 days of receiving your order. To initiate a refund, please contact us at reach@chyatech.online or 9403563691. Refunds will be processed within 5-7 working days and will be credited to your original method of payment.`)}
              >
                Refunds/Cancellations
              </p>
              <p
                className="text-lg font-semibold cursor-pointer hover:text-gray-300 mt-4"
                onClick={() => openModal('Pricing Policy', `All prices on our website are displayed in INR and include 18% GST. We reserve the right to change prices at any time without prior notice.`)}
              >
                Pricing
              </p>
            </div>
            <div>
              <p
                className="text-lg font-semibold cursor-pointer hover:text-gray-300"
                onClick={() => openModal('Contact Us', `Email: reach@chyatech.online, Phone: 9403563691`)}
              >
                Contact Us
              </p>
              <p
                className="text-lg font-semibold cursor-pointer hover:text-gray-300 mt-4"
                onClick={() => openModal('Products/Services', `At Chyatech Software Solutions, we offer a wide range of services including software design, development, and testing. Our expert team ensures that each project is delivered with precision and care, making us a trusted partner in your business success.`)}
              >
                Products/Services
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10 text-center">
          <p className="text-md font-semibold">@ 2024 Chyatech Software Solutions. All Rights Reserved.</p>
          <p className="text-md mt-2 font-semibold">Empowering businesses with innovative technology solutions.</p>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="bg-white text-black p-6 rounded-lg max-w-3xl mx-auto overflow-y-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <div className="flex flex-col">
          <h2 className="text-3xl font-bold mb-4">{modalTitle}</h2>
          <div className='text-wrap'>{modalContent}</div>
          <button
            onClick={closeModal}
            className="mt-4 bg-black text-white py-2 px-4 rounded hover:bg-gray-800"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Footer;
