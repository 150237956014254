import React from 'react';
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";

export default function ContactUs() {
  return (
    <div className='relative min-h-[300px] w-full bg-black text-white py-10'>
      <div className='absolute inset-0 bg-fixed bg-cover bg-center opacity-40' style={{ backgroundImage: `url('https://example.com/your-background-image.jpg')` }}></div>
      <div className='relative z-10 max-w-4xl mx-auto text-center'>
        <p className='text-4xl font-bold mb-6 text-dark-yellow'>Contact Us</p>
        <p className='text-lg font-semibold mb-4'>
          We're here for you, anytime. Whether you need assistance, have a question, or just want to chat, reach out to us and we'll be happy to help.
        </p>
        <p className='text-lg font-semibold mb-6'>
          Connect with us via our official email or through 24/7 WhatsApp support.
        </p>
        <div className='flex justify-center items-center space-x-4 mb-6'>
          <div className='flex flex-col items-center'>
            <AiOutlineMail className='text-5xl mb-2 text-red-800' />
            <p className='text-xl font-bold'>Email Us</p>
            <p className='text-md font-semibold text-red-800'>reach@chyatech.online</p>
          </div>
          <div className='flex flex-col items-center'>
            <BsWhatsapp className='text-5xl mb-2 text-green-500' />
            <p className='text-xl font-bold'>WhatsApp Support</p>
            <a href='https://wa.me/+919403563691?text=Hello!' className='text-md font-semibold text-green-500'>+91 94035 63691</a>
          </div>
        </div>
        <a href='https://wa.me/+919403563691?text=Hello!'>
          <button className='px-6 py-3 border border-white rounded font-semibold text-lg hover:bg-white hover:text-black transition duration-300'>
            Get In Touch
          </button>
        </a>
      </div>
    </div>
  );
}
