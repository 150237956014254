import  { useState,useEffect } from 'react';
import { RiLogoutCircleRLine, RiSettings3Line, RiFileList3Line, RiDashboardLine, RiMoneyDollarCircleLine } from "react-icons/ri";
import { CiUser } from "react-icons/ci";
import { Outlet, Link,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ClientDashboard = () => {
    const client = useSelector((state:any) => state.auth);
    const [clientName,setClientName] = useState('');
    const navigate = useNavigate();


  useEffect(()=>{
    if(client.user){
 setClientName(client.clientName)
    document.title = `${clientName}`
    }
    else{
        navigate('/patner/auth')
        return;
    }
   
  },[])
    

    return (
        <>
            <div className='bg-gray-800 min-h-[60px] border-b border-gray-700 shadow-sm flex justify-between px-4 items-center'>
                <div>
                    <p className='font-semibold text-xl md:text-2xl text-white'>{clientName}</p>
                </div>
                <div>
                    <ul className='flex space-x-4 md:space-x-6 items-center'>
                        <li className='text-xl md:text-2xl text-white'><CiUser /></li>
                        <li className='text-xl md:text-2xl text-white'><RiSettings3Line /></li>
                        <li className='text-xl md:text-2xl text-white'><RiLogoutCircleRLine /></li>
                    </ul>
                </div>
            </div>

            <div className='mt-6 flex justify-center px-4'>
                <div style={{ width: '100%', maxWidth: '80vw' }} className='text-white'>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6'>
                        <div className='bg-gray-900 p-3 md:p-4 rounded-lg shadow'>
                            <Link to="/patner/client/dashboard/project">
                                <RiDashboardLine className='text-3xl md:text-4xl text-blue-500' />
                                <p className='mt-2 text-sm md:text-lg font-semibold'>Current Projects</p>
                                <p className='text-xs md:text-sm text-gray-400'>Status: In Progress</p>
                            </Link>
                        </div>
                        <div className='bg-gray-900 p-3 md:p-4 rounded-lg shadow'>
                            <Link to="/patner/client/dashboard/payment-histroy">
                                <RiMoneyDollarCircleLine className='text-3xl md:text-4xl text-green-500' />
                                <p className='mt-2 text-sm md:text-lg font-semibold'>Payment History</p>
                                <p className='text-xs md:text-sm text-gray-400'>View your payments</p>
                            </Link>
                        </div>
                        <div className='bg-gray-900 p-3 md:p-4 rounded-lg shadow'>
                            <Link to="/patner/client/dashboard/pending-bills">
                                <RiFileList3Line className='text-3xl md:text-4xl text-yellow-500' />
                                <p className='mt-2 text-sm md:text-lg font-semibold'>Pending Bills</p>
                                <p className='text-xs md:text-sm text-gray-400'>Bills to be paid</p>
                            </Link>
                        </div>
                        <div className='bg-gray-900 p-3 md:p-4 rounded-lg shadow'>
                            <Link to="/patner/client/dashboard/account-settings">
                                <RiSettings3Line className='text-3xl md:text-4xl text-purple-500' />
                                <p className='mt-2 text-sm md:text-lg font-semibold'>Account Settings</p>
                                <p className='text-xs md:text-sm text-gray-400'>Manage your account</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-8 flex justify-center items-center px-4'>
                <Outlet />
            </div>
        </>
    );
}

export default ClientDashboard;


